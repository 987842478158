import Image from 'next/image';
import React from 'react';

import { HeroSection } from '../HeroSection';

export type THeroSectionHomePageProps = React.ComponentProps<
  typeof HeroSection
>;

const HeroSectionHomePage: React.FC<THeroSectionHomePageProps> = props => {
  return (
    <div className='relative'>
      <div className='pointer-events-none absolute inset-0'>
        <Image
          key='home-img-mobile'
          src='/images/home/mobile_img.png'
          alt='Home Mobile'
          width={276}
          height={109}
          className='absolute left-1/2 top-[2.4rem] w-[17.188rem] -translate-x-1/2 xl:hidden'
          priority
        />
        <Image
          key='home-desktop-left-top'
          src='/images/home/desktop-1.png'
          alt='Home desktop left Top'
          width={292}
          height={128}
          className='absolute bottom-0 left-[2.25rem] top-[2.5rem] hidden xl:block'
          fetchPriority='high'
        />
        <Image
          key='home-desktop-left-bottom'
          src='/images/home/desktop-2.png'
          alt='Home desktop left bottom'
          width={290}
          height={225}
          className='absolute  bottom-[2.75rem] left-[3rem] hidden xl:block 2xl:left-[8rem]'
          fetchPriority='high'
        />
        <Image
          key='home-desktop-right-top'
          src='/images/home/desktop-3.png'
          alt='Home destop right top'
          width={194}
          height={162}
          className='absolute bottom-0  right-[3rem] top-[3rem]  z-20 hidden xl:block 2xl:right-[9.75rem]'
          fetchPriority='high'
        />
        <Image
          key='home-desktop-right-bottom'
          src='/images/home/desktop-4.png'
          alt='Home destop right bottom'
          width={356}
          height={286}
          className='absolute bottom-[2.938rem] left-auto right-[2.2rem] z-10 hidden xl:block'
          fetchPriority='high'
        />
      </div>
      <HeroSection {...props} />
    </div>
  );
};

export default HeroSectionHomePage;
